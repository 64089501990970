.front:not(.panels-ipe-editing) {

	/*HOMEPAGE HERO*/
	.homepage-hero{

		.pane-bundle-image{
			max-height: 400px;
			overflow: hidden;
			z-index: -1;

			img.panopoly-image-full{
				margin-bottom: 0px;
				position: relative;
				height: 400px;
				object-fit: cover;
			}
		}

		.pane-bundle-text{
		    text-align: center;
	        padding-top: 3%;
	        padding-left: 0px;
	        padding-right: 0px;
		    position: relative;
		    background-color: rgba(0, 0, 0, .1);
		    z-index: 2;

		    &:after{
		    	content: ' ';
			    display: inline-block;
			    width: 100%;
			    height: 310px;
			    position: relative;
			    margin-top: -211px;
			    z-index: -10;
			    border: solid 15px rgba(255, 255, 255, .5);
	        	background-color: rgba(255, 255, 255, .2);
		    }

	        @include breakpoint ($xs){
    	        height: 285px;
    	        width: 100%;
    			margin-top: -335px;
	        }

	        @include breakpoint ($sm){
    	        height: 290px;
    	        width: 724px;
    			margin-top: -340px;
	        }

	        @include breakpoint ($md){
        	    height: 265px;
        	    width: 940px;
    			margin-top: -330px;
	        }

			@include breakpoint ($lg){
				height: 310px;
				width: 1140px;
        	    margin-top: -350px;
	        }

	        h2, h3, a{
	        	color: $wcm-white;
        	    text-shadow: 4px 4px 4px $wcm-black;
	        }

	        h2{
	        	font-size: 50px;
	        }

	        h3{
	        	font-size: 26px;
	        }
		}
	}

	/*HOMEPAGE CTA*/
	.homepage-cta-container{
		padding-top: 30px;

		.pane-bundle-image{
		    border-bottom: none;
		    position: relative;
    		display: block;

		    &:before{
		    	@mixin cta_after(){
		    		content: ' ';
				    height: stretch;
    				width: 100%;
    				left: 102%;
					position: absolute;
				    background: $wcm-dark-orange;
			        top: 0;
				    z-index: 1;
				    pointer-events: none;
				    mix-blend-mode: multiply;

		    	}

		    	@include breakpoint ($xs){
		    		@include cta-after();
			        	left: 0;
    					height: 100px;
		    	}

	    		@include breakpoint ($sm){
		    		@include cta-after();
            	    transform: skew(68deg);
                    -webkit-transform: skew(68deg);
					-moz-transform: skew(68deg);
					-ms-transform: skew(68deg);
					-o-transform: skew(68deg);
					height: 591px;
		    	}

		    	@include breakpoint ($md){
		    		@include cta-after();
	                transform: skew(58.3deg);
                    -webkit-transform: skew(58.3deg);
					-moz-transform: skew(58.3deg);
					-ms-transform: skew(58.3deg);
					-o-transform: skew(58.3deg);
					height: 580px;
		    	}

		    	@include breakpoint ($lg){
		    		@include cta-after();
                    transform: skew(62.7deg);
                    -webkit-transform: skew(62.7deg);
					-moz-transform: skew(62.7deg);
					-ms-transform: skew(62.7deg);
					-o-transform: skew(62.7deg);
					height: 590px;
		    	}
			}
		}

		@include breakpoint ($md){
			.cta_left.pane-bundle-image{
				border-right: solid 10px $wcm-white;
			}
			.cta_right.pane-bundle-image{
				border-left: solid 10px $wcm-white;
			}
		}

		@include breakpoint ($lg){
			.cta_left.pane-bundle-image{
				border-right: solid 10px $wcm-white;
			}
			.cta_right.pane-bundle-image{
				border-left: solid 10px $wcm-white;
			}
		}

		.pane-bundle-image{
		    float: left;

		    overflow: hidden;

		    @include breakpoint ($xs){
		    	height: 175px;
		    	margin-bottom: 10px;
		    	width: 100%;
		    }

		    @include breakpoint ($sm){
		    	height: 290px;
		    	width: 100%;
		    	margin-bottom: 10px;
		    }

		    @include breakpoint ($md){
		    	height: 290px;
		    	width: 50%;
		    }

		    @include breakpoint ($lg){
		    	height: 290px;
		    	width: 50%;
		    }

    		h3.pane-title{
	            color: $wcm-white;
	            font-size: 30px;
			    margin-top: 39px;
			    position: absolute;
			    pointer-events: none;
			    z-index: 2;

			    @include breakpoint ($xs){
			    	padding-left: 10%;
			    }

			    @include breakpoint ($sm){
			    	padding-left: 65%;
			    }

			    @include breakpoint ($md){
			    	padding-left: 25%;
			    }

			    @include breakpoint ($lg){
			    	padding-left: 40%;
			    }
    		}

    		img{
    			@include breakpoint ($xs){
    				padding-bottom: 0px;
    			}

    			@include breakpoint ($sm){
    				padding-bottom: 0px;
    				padding-top: 15px;
    				padding-right: 15px;
    			}

    			@include breakpoint ($md){
    				padding-top: 15px;
    				padding-right: 15px;
    			}

    			@include breakpoint ($lg){
    				padding-top: 15px;
    				padding-right: 15px;
    			}

				&:hover{
					transform: scale(1.1);
					  transition: 0.3s;
				}
    		}
		}
	}

	/*NEWS AND EVENTS*/
	.homepage-news-container{
		h3.pane-title{
			color: $wcm-dark-gray;
		    font-size: 20px;
		}
		.view-news{

			img.panopoly-image-quarter{
			    max-width: 25%;
			    width: 25%;
			    float: left;
			    margin-right: .5em;
			    margin-bottom: .25em;
			    margin-top: 1.25em;
			    float: right;
			    margin-left: 1.5em;
			}

			.views-row{
			    margin-bottom: 20px;
			    padding-bottom: 20px;
			    min-height: 318px;
		    	border-bottom: 1px solid $wcm-border-gray;

		    	h3.teaser-title{
		    		a:hover{
		    			color: $wcm-bright-orange;
		    		}
		    	}

		    	.views-field-field-event-link{
		    		margin-top: 5px;
		    	}

		    	.post-date{
				    text-transform: uppercase;
				    color: $wcm-med-gray;
				    font-family: "1898Sans-Bold",sans-serif;
				    font-size: 13px;
				    letter-spacing: 2px;
				    margin: 20px 0px;
				}
			}

			.views-row-last{
			    margin-bottom: 0px;
		    	padding-bottom: 20px;
		    	border-bottom: none;
			}
		}
	}

	.news-homepage-button{
		text-align: center;
	    padding: 20px 0px;
	    height: 60px;
	    background: $wcm-bg-gray;
	    margin: 10px 0px;

	    &:after{
	    	content: '\e80d';
		    color: $wcm-bright-orange;
		    padding-left: 10px;
		    padding-top: 0px;
		    font-size: 100%;
		    vertical-align: top;
		    font-family: "fontello";
		    font-style: normal;
		    font-weight: normal;
		    speak: none;
		    display: inline-block;
		    text-decoration: inherit;
		    text-align: center;
		    font-variant: normal;
		    text-transform: none;
		    line-height: 1em;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    text-decoration: none;
		    line-height: 1.75;
	    }
	}

	/*BECOME A GREEN LEADER CTA*/
	.homepage-green-leader{
		text-align: center;
		padding: 20px 0px 40px 0px;

		p{
			padding: 10px 0px;
		}

		.btn--small{
			width: 100%;
		}

		.btn-primary{
			background-color: $wcm-red;
			&:hover{
				background-color: $wcm-dark-orange;
			}
		}
	}

	/*BROWSE BY TOPIC*/
	.browse-by-topic{
	    display: inline-block;
		background: $wcm-bg-gray;
	    height: 160px;
		width: 100%;
		text-align: center;
    	margin-top: 20px;

    	h3.pane-title{
    		padding-bottom: 10px;
    		color: $wcm-dark-gray;
    		font-size: 18px;
    	}

		.select2-container{
			width: 90% !important;
		}
	}
}
