.not-front:not(.panels-ipe-editing){
	/*NEWS AND EVENTS*/
	.view-news {

		.view-filters{
			.views-exposed-form{
				.views-exposed-widget {
			    	display: inline-block;
			    	width: 100%;
				}

				padding-bottom: 25px;
				.select2-container{
					width: 340px !important;
				}
			}
		}

		img.panopoly-image-quarter {
		    max-width: 25%;
		    width: 25%;
		    float: left;
		    margin-right: .5em;
		    margin-bottom: .25em;
		    margin-top: 1.25em;
		    float: right;
		    margin-left: 1.5em;
		}

		.views-row{
		    margin-bottom: 40px;
		    padding-bottom: 40px;
	    	border-bottom: 1px solid $wcm-border-gray;

	    	.views-field-field-event-date {
			    font-weight: bold;
			    margin-top: 5px;
	    	}

	    	.views-field-field-event-link{
	    		margin-top: 5px;
	    	}
		}

		.views-row-last{
		    margin-bottom: 40px;
	    	padding-bottom: 40px;
	    	border-bottom: none;
		}
	}

	/*NEWS INDIVIDUAL PAGE*/
	.news-featured-image{
		.hero-image{
			img{
				width: auto;
			}
		}
	}

	.post-date{
	    text-transform: uppercase;
	    color: $wcm-med-gray;
	    font-family: "1898Sans-Bold",sans-serif;
	    font-size: 13px;
	    letter-spacing: 2px;
	    margin: 20px 0px;
	}
}