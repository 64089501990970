/*SIDEBAR*/
.information-column{
	.did-you-know{
		text-align: center;
		padding-bottom: 20px;
		
		.pane-title {
		    margin: 10px 0 5px;
		    color: #666;
		    font-size: 13px;
		    font-weight: 700;
		    letter-spacing: 2px;
		    text-transform: uppercase;
		    margin-bottom: 20px;
		    padding-bottom: 20px;
		    border-bottom: solid 1px $wcm-dark-orange;
		}
	}
}


/*VIDEO EMBED*/
.video_embed{
	width: 100%;
	height: 400px;
	frameborder: 0;
}