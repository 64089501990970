/**
 * Example Stub for creating WCMC Subthemes
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =Site-specific styles
----------------------------------------------------------*/

@import "_header";
@import "_navigation";
@import "_footer";
@import "_homepage";
@import "_internal_pages";
@import "_global";

.site-main{
    //DISTRO OVERRIDE FOR ACCESSIBILITY

    .marker, .form-required{
        color: $wcm-dark-orange;
    }

    .footer-contact__map-link a:hover{
        background-color: $wcm-dark-orange;
    }

    .footer-contact__site-name{
        a:hover, a:focus {
            color: $wcm-white;
        }
    }

    /*Sidebar*/
    .information-sidebar{
        .site-affiliation{
            font-weight: 700;
        }
    }

    /*Map*/
    .google-maps{
        iframe{
            width: 100%;
            height: 450px;
            border: 0px;
        }
    }

    /*Buttons*/
    .dndcinfo{
        color: #fff;
        background-color: $wcm-white !important;
        &:hover{
            background-color: $wcm-white !important;
            background-color: #b31b1b !important;
        }
    }

    .pagination>li>a:hover, .pagination>li>a:focus, .pagination>li>span:hover, .pagination>li>span:focus{
        color: $wcm-red;
    }
}

/* Events */

/*events callout*/
.events-callout {
  margin-bottom: 20px;
  margin-top: 20px;
  border-top: 1px solid $wcm-border-gray;
  border-bottom: 1px solid $wcm-border-gray;

  position: relative;
  padding: 15px 0 10px 130px;
  clear: both;

  h3 {
      margin-top: 10px;
  }

  &:before {
      display: inline-block;
      content: '';
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg);
      background-size: 50px 50px;
      background-repeat: no-repeat;
      background-position: 0 7px;
      height: 65px;
      width: 75px;
      position: absolute;
      left: 30px;
      border-right: 1px solid $wcm-border-gray;
  }
}

// Person list : Team Directory
.pane-person-list-panel-pane-1 {
  .view-person-list {
    .view-content {
      @include breakpoint($sm) {
        display: flex;
      }

      margin-left: -20px;
      margin-right: -20px;

      h3 {
        width: 100%;
        margin-bottom: 1rem;
        padding-left: 2rem;
      }
    }
  }
}
