/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*LOGO*/
.brand__lockup img {
  height: 84px; }

/*LEVEL 1 DRAWER NAV OVERRIDE*/
#primary-nav .level-1 {
  width: 15.5%; }

#primary-nav .level-1:nth-child(4), #primary-nav .level-1:nth-child(6) {
  width: 18%; }

#drawer-nav .level-2 {
  width: 33%;
  padding: 8px 30px 8px 0px; }

/*FOOTER NAV*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.front:not(.panels-ipe-editing) {
  /*HOMEPAGE HERO*/
  /*HOMEPAGE CTA*/
  /*NEWS AND EVENTS*/
  /*BECOME A GREEN LEADER CTA*/
  /*BROWSE BY TOPIC*/ }
  .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-image {
    max-height: 400px;
    overflow: hidden;
    z-index: -1; }
    .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-image img.panopoly-image-full {
      margin-bottom: 0px;
      position: relative;
      height: 400px;
      object-fit: cover; }
  .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text {
    text-align: center;
    padding-top: 3%;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2; }
    .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text:after {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 310px;
      position: relative;
      margin-top: -211px;
      z-index: -10;
      border: solid 15px rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.2); }
    @media screen and (max-width: 767px) {
      .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text {
        height: 285px;
        width: 100%;
        margin-top: -335px; } }
    @media screen and (min-width: 768px) {
      .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text {
        height: 290px;
        width: 724px;
        margin-top: -340px; } }
    @media screen and (min-width: 992px) {
      .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text {
        height: 265px;
        width: 940px;
        margin-top: -330px; } }
    @media screen and (min-width: 1200px) {
      .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text {
        height: 310px;
        width: 1140px;
        margin-top: -350px; } }
    .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text h2, .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text h3, .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text a {
      color: #fff;
      text-shadow: 4px 4px 4px #000000; }
    .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text h2 {
      font-size: 50px; }
    .front:not(.panels-ipe-editing) .homepage-hero .pane-bundle-text h3 {
      font-size: 26px; }
  .front:not(.panels-ipe-editing) .homepage-cta-container {
    padding-top: 30px; }
    .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
      border-bottom: none;
      position: relative;
      display: block; }
      @media screen and (max-width: 767px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image:before {
          content: ' ';
          height: stretch;
          width: 100%;
          left: 102%;
          position: absolute;
          background: #cf4520;
          top: 0;
          z-index: 1;
          pointer-events: none;
          mix-blend-mode: multiply;
          left: 0;
          height: 100px; } }
      @media screen and (min-width: 768px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image:before {
          content: ' ';
          height: stretch;
          width: 100%;
          left: 102%;
          position: absolute;
          background: #cf4520;
          top: 0;
          z-index: 1;
          pointer-events: none;
          mix-blend-mode: multiply;
          transform: skew(68deg);
          -webkit-transform: skew(68deg);
          -moz-transform: skew(68deg);
          -ms-transform: skew(68deg);
          -o-transform: skew(68deg);
          height: 591px; } }
      @media screen and (min-width: 992px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image:before {
          content: ' ';
          height: stretch;
          width: 100%;
          left: 102%;
          position: absolute;
          background: #cf4520;
          top: 0;
          z-index: 1;
          pointer-events: none;
          mix-blend-mode: multiply;
          transform: skew(58.3deg);
          -webkit-transform: skew(58.3deg);
          -moz-transform: skew(58.3deg);
          -ms-transform: skew(58.3deg);
          -o-transform: skew(58.3deg);
          height: 580px; } }
      @media screen and (min-width: 1200px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image:before {
          content: ' ';
          height: stretch;
          width: 100%;
          left: 102%;
          position: absolute;
          background: #cf4520;
          top: 0;
          z-index: 1;
          pointer-events: none;
          mix-blend-mode: multiply;
          transform: skew(62.7deg);
          -webkit-transform: skew(62.7deg);
          -moz-transform: skew(62.7deg);
          -ms-transform: skew(62.7deg);
          -o-transform: skew(62.7deg);
          height: 590px; } }
    @media screen and (min-width: 992px) {
      .front:not(.panels-ipe-editing) .homepage-cta-container .cta_left.pane-bundle-image {
        border-right: solid 10px #fff; }
      .front:not(.panels-ipe-editing) .homepage-cta-container .cta_right.pane-bundle-image {
        border-left: solid 10px #fff; } }
    @media screen and (min-width: 1200px) {
      .front:not(.panels-ipe-editing) .homepage-cta-container .cta_left.pane-bundle-image {
        border-right: solid 10px #fff; }
      .front:not(.panels-ipe-editing) .homepage-cta-container .cta_right.pane-bundle-image {
        border-left: solid 10px #fff; } }
    .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
      float: left;
      overflow: hidden; }
      @media screen and (max-width: 767px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
          height: 175px;
          margin-bottom: 10px;
          width: 100%; } }
      @media screen and (min-width: 768px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
          height: 290px;
          width: 100%;
          margin-bottom: 10px; } }
      @media screen and (min-width: 992px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
          height: 290px;
          width: 50%; } }
      @media screen and (min-width: 1200px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image {
          height: 290px;
          width: 50%; } }
      .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image h3.pane-title {
        color: #fff;
        font-size: 30px;
        margin-top: 39px;
        position: absolute;
        pointer-events: none;
        z-index: 2; }
        @media screen and (max-width: 767px) {
          .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image h3.pane-title {
            padding-left: 10%; } }
        @media screen and (min-width: 768px) {
          .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image h3.pane-title {
            padding-left: 65%; } }
        @media screen and (min-width: 992px) {
          .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image h3.pane-title {
            padding-left: 25%; } }
        @media screen and (min-width: 1200px) {
          .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image h3.pane-title {
            padding-left: 40%; } }
      @media screen and (max-width: 767px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image img {
          padding-bottom: 0px; } }
      @media screen and (min-width: 768px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image img {
          padding-bottom: 0px;
          padding-top: 15px;
          padding-right: 15px; } }
      @media screen and (min-width: 992px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image img {
          padding-top: 15px;
          padding-right: 15px; } }
      @media screen and (min-width: 1200px) {
        .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image img {
          padding-top: 15px;
          padding-right: 15px; } }
      .front:not(.panels-ipe-editing) .homepage-cta-container .pane-bundle-image img:hover {
        transform: scale(1.1);
        transition: 0.3s; }
  .front:not(.panels-ipe-editing) .homepage-news-container h3.pane-title {
    color: #555555;
    font-size: 20px; }
  .front:not(.panels-ipe-editing) .homepage-news-container .view-news img.panopoly-image-quarter {
    max-width: 25%;
    width: 25%;
    float: left;
    margin-right: .5em;
    margin-bottom: .25em;
    margin-top: 1.25em;
    float: right;
    margin-left: 1.5em; }
  .front:not(.panels-ipe-editing) .homepage-news-container .view-news .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    min-height: 318px;
    border-bottom: 1px solid #dddddd; }
    .front:not(.panels-ipe-editing) .homepage-news-container .view-news .views-row h3.teaser-title a:hover {
      color: #e7751d; }
    .front:not(.panels-ipe-editing) .homepage-news-container .view-news .views-row .views-field-field-event-link {
      margin-top: 5px; }
    .front:not(.panels-ipe-editing) .homepage-news-container .view-news .views-row .post-date {
      text-transform: uppercase;
      color: #666666;
      font-family: "1898Sans-Bold",sans-serif;
      font-size: 13px;
      letter-spacing: 2px;
      margin: 20px 0px; }
  .front:not(.panels-ipe-editing) .homepage-news-container .view-news .views-row-last {
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-bottom: none; }
  .front:not(.panels-ipe-editing) .news-homepage-button {
    text-align: center;
    padding: 20px 0px;
    height: 60px;
    background: #f7f7f7;
    margin: 10px 0px; }
    .front:not(.panels-ipe-editing) .news-homepage-button:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      padding-top: 0px;
      font-size: 100%;
      vertical-align: top;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      line-height: 1.75; }
  .front:not(.panels-ipe-editing) .homepage-green-leader {
    text-align: center;
    padding: 20px 0px 40px 0px; }
    .front:not(.panels-ipe-editing) .homepage-green-leader p {
      padding: 10px 0px; }
    .front:not(.panels-ipe-editing) .homepage-green-leader .btn--small {
      width: 100%; }
    .front:not(.panels-ipe-editing) .homepage-green-leader .btn-primary {
      background-color: #b31b1b; }
      .front:not(.panels-ipe-editing) .homepage-green-leader .btn-primary:hover {
        background-color: #cf4520; }
  .front:not(.panels-ipe-editing) .browse-by-topic {
    display: inline-block;
    background: #f7f7f7;
    height: 160px;
    width: 100%;
    text-align: center;
    margin-top: 20px; }
    .front:not(.panels-ipe-editing) .browse-by-topic h3.pane-title {
      padding-bottom: 10px;
      color: #555555;
      font-size: 18px; }
    .front:not(.panels-ipe-editing) .browse-by-topic .select2-container {
      width: 90% !important; }

.not-front:not(.panels-ipe-editing) {
  /*NEWS AND EVENTS*/
  /*NEWS INDIVIDUAL PAGE*/ }
  .not-front:not(.panels-ipe-editing) .view-news .view-filters .views-exposed-form {
    padding-bottom: 25px; }
    .not-front:not(.panels-ipe-editing) .view-news .view-filters .views-exposed-form .views-exposed-widget {
      display: inline-block;
      width: 100%; }
    .not-front:not(.panels-ipe-editing) .view-news .view-filters .views-exposed-form .select2-container {
      width: 340px !important; }
  .not-front:not(.panels-ipe-editing) .view-news img.panopoly-image-quarter {
    max-width: 25%;
    width: 25%;
    float: left;
    margin-right: .5em;
    margin-bottom: .25em;
    margin-top: 1.25em;
    float: right;
    margin-left: 1.5em; }
  .not-front:not(.panels-ipe-editing) .view-news .views-row {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #dddddd; }
    .not-front:not(.panels-ipe-editing) .view-news .views-row .views-field-field-event-date {
      font-weight: bold;
      margin-top: 5px; }
    .not-front:not(.panels-ipe-editing) .view-news .views-row .views-field-field-event-link {
      margin-top: 5px; }
  .not-front:not(.panels-ipe-editing) .view-news .views-row-last {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: none; }
  .not-front:not(.panels-ipe-editing) .news-featured-image .hero-image img {
    width: auto; }
  .not-front:not(.panels-ipe-editing) .post-date {
    text-transform: uppercase;
    color: #666666;
    font-family: "1898Sans-Bold",sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
    margin: 20px 0px; }

/*SIDEBAR*/
.information-column .did-you-know {
  text-align: center;
  padding-bottom: 20px; }
  .information-column .did-you-know .pane-title {
    margin: 10px 0 5px;
    color: #666;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #cf4520; }

/*VIDEO EMBED*/
.video_embed {
  width: 100%;
  height: 400px;
  frameborder: 0; }

.site-main {
  /*Sidebar*/
  /*Map*/
  /*Buttons*/ }
  .site-main .marker, .site-main .form-required {
    color: #cf4520; }
  .site-main .footer-contact__map-link a:hover {
    background-color: #cf4520; }
  .site-main .footer-contact__site-name a:hover, .site-main .footer-contact__site-name a:focus {
    color: #fff; }
  .site-main .information-sidebar .site-affiliation {
    font-weight: 700; }
  .site-main .google-maps iframe {
    width: 100%;
    height: 450px;
    border: 0px; }
  .site-main .dndcinfo {
    color: #fff;
    background-color: #fff !important; }
    .site-main .dndcinfo:hover {
      background-color: #fff !important;
      background-color: #b31b1b !important; }
  .site-main .pagination > li > a:hover, .site-main .pagination > li > a:focus, .site-main .pagination > li > span:hover, .site-main .pagination > li > span:focus {
    color: #b31b1b; }

/* Events */
/*events callout*/
.events-callout {
  margin-bottom: 20px;
  margin-top: 20px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  position: relative;
  padding: 15px 0 10px 130px;
  clear: both; }
  .events-callout h3 {
    margin-top: 10px; }
  .events-callout:before {
    display: inline-block;
    content: '';
    background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: 0 7px;
    height: 65px;
    width: 75px;
    position: absolute;
    left: 30px;
    border-right: 1px solid #dddddd; }

.pane-person-list-panel-pane-1 .view-person-list .view-content {
  margin-left: -20px;
  margin-right: -20px; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-1 .view-person-list .view-content {
      display: flex; } }
  .pane-person-list-panel-pane-1 .view-person-list .view-content h3 {
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 2rem; }
